
import React from 'react';

function PageNotFound(props) {


    return (<div className={'vh-100 pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5">

            <h1 className={'title-section'}>Упс...</h1>

            <p className="lead">
                Помилка 404 <br/>
                Запит URL / Сторанкине знайдено на сервері. <br/>
                Це все що ми знаємо.
            </p>

            <div className="pt-5 pb-5">

                <a href="/" className="btn-custom-black">На головну</a>

            </div>
        </div>
    </div>);
}

export default PageNotFound;

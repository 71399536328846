
import '../App.css';
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useParams} from "react-router";
import PageThank from "./thank";
import PageContact from "./contact";
import PageQuestions from "./questions";
import PageNotFound from "./404";
import Loader from "../components/loader";

function InitPage() {

    let {slug} = useParams();

    const [statisticsID, setStatisticsID] = useState();

    const statisticsRecord = () => {
        axios.post('https://cloud.growthukraine.com/api/v1/statistics/record', [], {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }).then(res => {
            console.log(res.data.data)
            setStatisticsID(res.data.data.id)
        })
    }

    const [isLoad, setLoad] = useState(true);
    const [isLoadData, setLoadData] = useState(false);

    const [data, setData] = useState();
    const [questionnaire, setDataQuestionnaire] = useState();
    const [questions, setDataQuestions] = useState();

    // social-bel-anketa

    const fetchData = () => {

        setLoad(true);
        axios.get('https://cloud.growthukraine.com/api/v1/questionnaire/' + slug).then(res => {

            if(res.data.data){

                setLoadData(true);
                setData(res.data.data)
                setDataQuestionnaire(res.data.data.questionnaire)
                setDataQuestions(res.data.data?.questions)
            }

            setLoad(false);
        })
    };

    useEffect(() => {
        statisticsRecord();
        fetchData();
    }, []);

    const [rid, setRid] = useState();

    const handleSetRid = (value) => {
        setRid(value);
    }

    const [section, setSection] = useState(0);

    const _sections = [
        <PageQuestions
            data={data}
            questionnaire={questionnaire}
            questions={questions}
            sid={statisticsID}
            funcRid={handleSetRid}
            nextBtnClick={() => setSection(section+1) }
            thankBtnClick={() => setSection(section+2) }
        />,
        <PageContact
            sectionValue={section}
            questionnaire={questionnaire}
            rid={rid}
            sectionLength={3}
            nextBtnClick={() => setSection(section+1) }
        />,
        <PageThank
            giftType={questionnaire?.gift?.type}
        />
    ];

    if(isLoad){
        return <Loader />
    }

    return (
        <div className="container mt-2">

            {
                isLoadData ? _sections[section] : <PageNotFound />
            }

        </div>
    );
}

export default InitPage;


import '../App.css';
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useParams} from "react-router";
import PageThank from "./thank";
import PageContact from "./contact";
import PageNotFound from "./404";
import Helmet from "react-helmet";

function PageQuestions(props) {

    let {slug} = useParams();

    const data = props.data;
    const questionnaire = props.questionnaire;
    const questions = props.questions;
    const statisitcsId = props.sid

    // const [loadData, setLoadData] = useState();
    //
    // const [data, setData] = useState();
    // const [questionnaire, setDataQuestionnaire] = useState();
    // const [questions, setDataQuestions] = useState();
    //
    // // social-bel-anketa
    //
    // useEffect(() => {
    //
    //     axios.get('https://cloud.growthukraine.com/api/v1/questionnaire/' + slug).then(res => {
    //         setData(res.data.data)
    //         setDataQuestionnaire(res.data.data.questionnaire)
    //         setDataQuestions(res.data.data.questions)
    //         setLoadData(true)
    //     })
    // }, []);

    const [answers, setAnswers] = useState([]);

    const handleSetAnswers = (e) => {
        var value = e.target.value;
        setAnswers([...answers, value]);
    }

    const sendAnswers = (e) => {

        e.preventDefault();
        // questionnnnaires_id
        // answers[]
        // gift_name

        if(answers.length === 0){
            alert('Оберіть відповіді!')
        }else {

            let data = {
                questionnnnaires_id: questionnaire.id,
                statistics_id: statisitcsId,
                gift_name: questionnaire.gift.type,
                // gift_value: questionnaire.gift.type,
                answers: answers
            }

            axios.post('https://cloud.growthukraine.com/api/v1/questionnaire/store',
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                    }
                }
            ).then(res => {
                console.log(res.data)

                if(res.data.data.id !== null){
                    props.funcRid(res.data.data.id);

                    if(questionnaire?.gift?.type === 'gift'){
                        props.nextBtnClick();
                    }else{
                        props.thankBtnClick();
                    }

                }

            }).catch(error => {
                console.log(error.message)
            });

        }

    }

    return (
        <div className="container">

            <Helmet>
                <meta charSet="utf-8" />
                <title> {questionnaire?.title ?? 'TIITLE'} </title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>

            <div className="container bg-white fixed-top- pt-3 pb-2 border-bottom">
                <h1 className={'display-6 lead font-weight-bolt'}>
                    {questionnaire?.title ?? 'TIITLE'}
                </h1>

                <p className="text-secondary lead">
                    {questionnaire?.description ?? 'DESCRIPTION'}
                </p>
            </div>

            <div className="questions overflow_auto pt-3">
                {/*style={{marginTop: '15vh'}}>*/}
                {
                    data?.questions && data.questions.map((item, i) => <div key={item.id} id={'q_' + item.id} className="card1 mb-4 p-3 border shadow-sm">

                        <div className="card-header pb-2">
                            <small className="text-secondary">
                                Питання №{i+1} {item.optional.required && '*'}
                            </small>
                            <h4 className={'mt-2 font-weight-bold'}>
                                {item?.title ?? 'QUESTION'}
                            </h4>
                            {item.optional.multiple && <small className="text-gray-500">(Оберіть кілька варіантів відповіді)</small>}
                        </div>
                        <div className="card-body">

                            {item.answers.sort((a, b) => a.index < b.index).map((annswer, i) => <div className="form-check" key={i}>
                                <input className="form-check-input" required={item.optional.required} name={'answers[]'} onClick={(e)=>handleSetAnswers(e)} type="checkbox" value={item.id + '_' + annswer} id={'q#' + item.id + 'a#'+i} />
                                <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                                    {annswer}
                                </label>
                            </div>)}

                            {
                                item.optional.custom && <input type="text" name={'answers[]'} id={'q#' + item.id + '_custom_answer#' + i} className={'form-control w-md-50 w-100'} placeholder={'Вдасна відповідь'}/>
                            }
                        </div>

                    </div>)
                }

                <div>
                    <small className="text-gray-500">
                        * - обов'язкове питання
                    </small>
                </div>

                <div className="p-2">
                    <input type="checkbox" className={'m-1'} checked />
                    <label htmlFor="">Я, даю згоду на оброблення моїх даних</label>
                </div>
            </div>

            <div className="d-flex justify-content-end align-items-center mt-5 mb-5">

                <button className={'btn btn-primary rounded-0 border shadow-lg p-3 align-items-center d-flex'} onClick={sendAnswers}>

                    {
                        questionnaire?.gift?.type === 'gift' && <>
                            <svg className="w-6 h-6 text-gray-800 dark:text-white m-1" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                 viewBox="0 0 24 24">
                                <path
                                    d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z"/>
                            </svg>
                            Отримати нагороду
                        </>
                    }

                    {
                        questionnaire?.gift?.type === 'default' && <>
                            <svg className="w-6 h-6 text-gray-800 dark:text-white m-1" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                            </svg>
                            Відправити
                        </>
                    }

                </button>

            </div>
        </div>

    );
}

export default PageQuestions;



import React from 'react';
import Helmet from "react-helmet";

function PageThank(props) {


    return (<div className={'container'}>

        <Helmet>
            <meta charSet="utf-8" />
            <title> {props.questionnaire?.title ?? 'TIITLE'} </title>
            <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>

        <div className="questions overflow-auto p-5 pt-0 pb-0 border1 rounded-5" style={{height: 'auto'}}>

            <div className="col-md-6 col-12 m-auto shadow-sm border p-5 text-center">

                <h1 className={'display-1'}>
                    Дякуємо!
                </h1>

                {
                    props.giftType === 'default' && <div>
                        <img className={'img-fluid'} src="https://img.freepik.com/premium-vector/green-check-mark-3d-icon-vector-illustration_567896-126.jpg" alt="thank"/>
                        <p className="text-secondary mt-3">
                            Ми щиро вдячні вам за приділений час на проходження анонімного опитування.
                        </p>
                    </div>
                }

                {
                    props.giftType === 'gift' && <div>
                        <img className={'img-fluid'} src="https://i.pinimg.com/originals/65/f3/19/65f3194d1155942a8848e97670bf0500.png" alt="delivery"/>
                        <p className="text-secondary mt-3">
                            Ваша нагорода вже вдорозі до вас.
                        </p>
                    </div>
                }

                <h3 className={'display-6'}>
                    Вдалого вам дня!
                </h3>

                <div className={'mt-5'}>
                    <small className="text-secondary">
                        Опитування від <a href="" className={'text-decoration-none'}> ГО "Інформаційний фонд"</a>
                    </small>
                </div>

            </div>

        </div>

        <div className={'mt-5 text-center pb-2'}>
            <small className="text-secondary">
                Cтворено на платформі <a href="https://cloud.growthukraine.com" className={'text-decoration-none'}> questionnaire</a>
            </small>
        </div>

    </div>);
}

export default PageThank;

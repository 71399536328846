import React from "react";
import {Atom} from "react-loading-indicators";


function StartPage(){
    return <div className={'d-flex justify-content-center align-items-center vh-100'}>
        <div className="p-3 border text-center shadow-sm">
            <h1 className={'lead'}>Welcome</h1>
            <br/>
            <Atom color="#263326" size="medium" text="questionnaires" textColor="#263326" />
        </div>
    </div>
}

export default StartPage;



import React, {useEffect, useState} from 'react';
import axios from "axios";
import Helmet from "react-helmet";

function PageContact(props) {

    useEffect(() => {
        document.getElementById('box').classList.add('gift-select');
        setGiftValue('box')
    }, []);

    const _gifts = [
        {
            id: "pencil",
            name: "Ручка",
            icon: "https://cdn3d.iconscout.com/3d/premium/thumb/pen-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--pencil-write-edit-writing-education-pack-school-illustrations-4089429.png?f=webp"
        },
        {
            id: "sticker",
            name: "Наклейки",
            icon: "https://cdn3d.iconscout.com/3d/premium/thumb/social-media-3d-icon-download-in-png-blend-fbx-gltf-file-formats--like-logo-feedback-marketing-advertisement-pack-branding-icons-4863035.png"
        },
        {
            id: "note",
            name: "Блокнот",
            icon: "https://cdn3d.iconscout.com/3d/premium/thumb/notebook-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--diary-book-note-education-pack-school-illustrations-4577209.png?f=webp"
        },
        {
            id: "box",
            name: "Секретий подарунок",
            icon: "https://cdn3d.iconscout.com/3d/premium/thumb/shipping-box-3d-icon-download-in-png-blend-fbx-gltf-file-formats--package-cardboard-qr-delivery-scan-scanning-pack-icons-6220841.png?f=webp"
        }
    ];

    const _handelSelect = (value) => {

        var _arr = ['pencil', 'note', 'sticker', 'box'];
        var result = _arr.filter((v, i) => v !== value);

        document.getElementById(value).classList.add('gift-select');
        setGiftValue(value)

        result.map((val, i) => {
            document.getElementById(val).classList.remove('gift-select');
        });

    }

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [addressRegion, setAddressRegion] = useState();
    const [addressCity, setAddressCity] = useState();
    const [addressValue, setAddressValue] = useState();
    const [addressIndex, setAddressIndex] = useState();

    const [giftValue, setGiftValue] = useState();

    const sendContacts = (e) => {

        e.preventDefault();

        _handleSendContacts();

        // alert('Заповніть поля контактної форми для відправи подарунка!')
    }

    const _handleSendContacts = () => {

        let data = {
            rid: props.rid,
            contacts_name: name,
            contacts_phone: phone,
            contacts_email: email,
            contacts_address: addressRegion + ', ' + addressCity + ', ' + addressValue + ', ' + addressIndex,
            gift_value: giftValue ?? ''
        }

        axios.post('https://cloud.growthukraine.com/api/v1/questionnaire/store/contacts',
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }
        ).then(res => {
            console.log(res.data)

            if(res.data.data !== null){
                props.nextBtnClick();
            }

        }).catch(error => {
            console.log(error.message)
        });
    }


    return (<div className={'col-md-6 col-12 m-auto pb-2'}>

        <Helmet>
            <meta charSet="utf-8" />
            <title> {props.questionnaire?.title ?? 'TIITLE'} </title>
            <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>

        <form onSubmit={sendContacts} method={'POST'} className="questions overflow-auto p-5 border" style={{height: 'auto'}}>

            {
                props.questionnaire.gift.type === 'gift' && <div>
                    <h2>
                        Подарунок
                    </h2>
                    <p className={'text-secondary'}>
                        Ми вдячні за ваш час приділений на проходження опитування.
                        Оберіть взнак подяки один із подарунків.
                    </p>
                    <div id={'gifts'} className="d-flex flex-wrap">

                        {
                            _gifts.map((gift, i) =>  <div id={gift.id} className="col-md-6 col-6" onClick={() => _handelSelect(gift.id)}>

                                <div className="border p-4">
                                    <img className={'img-fluid'} width={75} src={gift.icon} alt={gift.name}/>
                                    <h5 className={'mt-2 lead'}>
                                        {gift.name}
                                    </h5>
                                </div>

                            </div>)
                        }
                    </div>
                </div>
            }

            <div className="m-auto p-1 pt-3">

                <h6>
                    Контакти
                </h6>

                <div className="d-flex flex-wrap">

                    <div className="col-md-6 col-12 p-1">
                        <input type="email" aria-label="Email" required value={email} onChange={(e) => setEmail(e.target.value)} className="form-control rounded-0 border-0 border-bottom" placeholder={'Email'}/>
                    </div>

                    <div className="col-md-6 col-12 p-1">
                        <input type="tel" aria-label="phone" required value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control rounded-0 border-0 border-bottom" placeholder={'Телефон'}/>
                    </div>

                    <div className="col-md-6 col-12 p-1">
                        <input type="text" aria-label="name" required value={name} onChange={(e) => setName(e.target.value)} className="form-control rounded-0 border-0 border-bottom" placeholder={'ПІБ'}/>
                    </div>

                    <div className="col-md-6 col-12 p-1">
                        <input type="text" aria-label="region" required value={addressRegion} onChange={(e) => setAddressRegion(e.target.value)} className="form-control rounded-0 border-0 border-bottom" placeholder={'Область'}/>
                    </div>

                    <div className="col-md-6 col-12 p-1">
                        <input type="text" aria-label="city" required value={addressCity} onChange={(e) => setAddressCity(e.target.value)} className="form-control rounded-0 border-0 border-bottom" placeholder={'Місто'}/>
                    </div>

                    <div className="col-md-6 col-12 p-1">
                        <input type="text" aria-label="address" required value={addressValue} onChange={(e) => setAddressValue(e.target.value)} className="form-control rounded-0 border-0 border-bottom" placeholder={'Адреса'}/>
                    </div>

                    <div className="col-md-6 col-12 p-1">
                        <input type="text" aria-label="index" required value={addressIndex} onChange={(e) => setAddressIndex(e.target.value)} className="form-control rounded-0 border-0 border-bottom" placeholder={'Індекс'}/>
                    </div>

                </div>

            </div>

            <div className="btn-send mt-5">
                <button type={"submit"} className={'btn btn-primary rounded-0 border shadow-lg p-3 align-items-center d-flex w-100 justify-content-center'} >
                    <svg className="w-6 h-6 text-gray-800 dark:text-white m-1" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                         viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                    </svg>
                    Відправити
                </button>
            </div>

        </form>
    </div>);
}

export default PageContact;

import React from "react";
import {OrbitProgress} from "react-loading-indicators";


function Loader(){

    return <div className={'d-flex justify-content-center align-items-center vh-100'}>
        <div className={'text-center'}>
            <OrbitProgress color="#263326" size="medium" text="" textColor="#263326" />
            <br/>
            <h1 className={'lead'}>Завантаження...</h1>
        </div>
    </div>
}

export default Loader;

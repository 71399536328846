
import React, {useEffect, useRef, useState} from "react";

import {Routes, Route, Link, Navigate} from "react-router-dom";
import InitPage from "./page/init";
import PageNotFound from "./page/404";
import StartPage from "./page/start";

function App() {

  return (
    <>
      <Routes>
          <Route index exact path={'/'} element={<StartPage />}/>

          <Route index exact path={'/:slug'} element={ <InitPage />}/>

          <Route path="*" element={<PageNotFound/>}/>

      </Routes>
    </>
  );
}

export default App;
